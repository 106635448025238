import { tw } from '@/utils/tw';
import BasePage4XXTheme from 'base/components/Page4XX/theme';

const Page4XX = tw.theme({
  extend: BasePage4XXTheme,
  slots: {
    headline: `text-headline-2xl`,
    description: `text-body-md [&_a]:text-primary-600`,
  },
});

export default Page4XX;
