import { Button } from '@/components/Button';
import { Link } from '@/components/Link';
import AppStoreBanner from '@/svenskdam/public/image/app-store-banner.svg';
import GooglePlayBanner from '@/svenskdam/public/image/google-play-banner.svg';
import SvenskdamLogo from '@/svenskdam/public/image/svenskdam-logo.svg';
import { showOneTrustConsent } from '@/utils/oneTrust';
import { StandaloneFooter, StandaloneFooterProps } from 'base/components/Footer';
import { Footer } from 'base/components/Footer/Footer';
import GoogleNewsIcon from 'base/public/image/google-news.svg';
import { useMobileAppState } from 'lib/hooks/useMobileAppState';

const currentYear = new Date().getFullYear();

const copyright: StandaloneFooterProps['copyright'] = (
  <span>
    För prenumerationsärenden, ring 042 444 30 00 / Ansvarig utgivare Johan T Lindwall © Copyright {currentYear}{' '}
    <a href="https://www.svenskdam.se" aria-label="Länk till Svensk Dams startsida">
      svenskdam.se
    </a>{' '}
    / Svensk Damtidning är en del av{' '}
    <a target="_blank" href="https://www.aller.se" aria-label="Länk till Aller Medias hemsida">
      Aller Media
    </a>
    . Humlegårdsgatan 6, 114 46 Stockholm, 08 679 46 00, Organisationsnummer 556002-8325.
  </span>
);

const description: StandaloneFooterProps['description'] = 
    'Svensk Damtidning – är platsen för dig som vill ha stenkoll på kungliga nyheter och den svenska nöjesvärlden.'

const editors: StandaloneFooterProps['editors'] = `Chefredaktör och ansvarig utgivare: Johan T Lindwall`;

const logo: StandaloneFooterProps['logo'] = <SvenskdamLogo />;

const soMe: StandaloneFooterProps['soMe'] = (
  <Footer.SoMe.Group>
    <Footer.SoMe.Headline>Följ oss</Footer.SoMe.Headline>

    <Footer.SoMe
      href="https://www.facebook.com/svenskdamtidning/?locale=sv_SE"
      name="facebook"
      aria-label="Länk till Svensk Dams Facebook-sida"
    />
    <Footer.SoMe
      href="https://www.instagram.com/svenskdamtidning/"
      name="instagram"
      aria-label="Länk till Svensk Dams Instagram-sida"
    />
    <Footer.SoMe href="https://x.com/svenskdam" name="x" aria-label="Länk till Svensk Dams X-sida" />
    <Button
      link={{
        href: 'https://news.google.com/publications/CAAiEMOO7LLrA0PM4gJfbc3NYiYqFAgKIhDDjuyy6wNDzOICX23NzWIm?hl=sv&gl=SE&ceid=SE%3Asv',
        target: '_blank',
      }}
      options={{
        size: 'small',
        'aria-label': 'Länk till Google nyheter',
      }}
      content={
        <>
          <GoogleNewsIcon className="w-6" />
          <span className="whitespace-nowrap">Google nyheter</span>
        </>
      }
    />
  </Footer.SoMe.Group>
);

export const SvenskdamStandaloneFooter: typeof StandaloneFooter = () => {
  const { isMobileApp } = useMobileAppState();

  const columns: StandaloneFooterProps['columns'] = (
    <>
      <Footer.Column>
        <Footer.List.Title>Om Svensk dam</Footer.List.Title>
        <Footer.List>
          <Footer.List.Item>
            <Link
              href="/om-oss"
              content="Om oss"
              aria-label="Länk till sida med information om Svensk Dam och dess historia"
            />
          </Footer.List.Item>
          <Footer.List.Item>
            <Link
              href="/nyhetsbrev"
              content="Nyhetsbrev"
              aria-label="Länk till sida där man kan registrera sig för Svensk Dams nyhetsbrev"
            />
          </Footer.List.Item>
          <Footer.List.Item>
            <Link
              href="/alla-amnen"
              content="Alla ämnen"
              aria-label="Länk till sida där alla kategorier på Svensk Dam listas i alfabetisk ordning"
            />
          </Footer.List.Item>
          <Footer.List.Item>
            <Link
              href="/vara-skribenter"
              content="Våra skribenter"
              aria-label="Länk till sidan där alla skribenter för Svensk Dam listas"
            />
          </Footer.List.Item>
          <Footer.List.Item>
            <Link
              href="/redaktionell-policy"
              content="Redaktionell policy på Svensk Dam"
              aria-label="Länk till information om Svensk Dams redaktionella policy"
            />
          </Footer.List.Item>
          <Footer.List.Item>
            <Link href="/om-cookies" content="Om cookies" aria-label="Länk till information om cookies på Svensk Dam" />
          </Footer.List.Item>
          <Footer.List.Item>
            <button
              type="button"
              aria-label="Knapp till att hantera cookies inställningar på Svensk Dam"
              onClick={showOneTrustConsent}
            >
              Hantera preferenser
            </button>
          </Footer.List.Item>
          <Footer.List.Item>
            <Link
              href="https://www.aller.se/integritetspolicy/"
              target="_blank"
              content="Integritetspolicy"
              aria-label="Länk till information om Aller Medias integritetspolicy"
            />
          </Footer.List.Item>
          <Footer.List.Item>
            <Link
              href="https://www.aller.se/ai-policy/"
              target="_blank"
              content="Aller Medias AI-policy"
              aria-label="Länk till Aller Medias integritetspolicy"
            />
          </Footer.List.Item>
          <Footer.List.Item>
            <Link
              href="/creative-studio/riktlinjer-kommersiellt-innehall/10283154"
              content="Kommersiell policy Svensk Dam"
              aria-label="Länk till Svensk Dams kommersiella policy"
            />
          </Footer.List.Item>
        </Footer.List>
      </Footer.Column>

      <Footer.Column>
        <Footer.List.Title>Svenskdam.se</Footer.List.Title>
        <Footer.List>
          <Footer.List.Item>
            <Link href="/kungligt" content="Kungligt" aria-label="Länk till kategorin Kungligt" />
          </Footer.List.Item>
          <Footer.List.Item>
            <Link href="/noje" content="Kändis" aria-label="Länk till kategorin Kändis" />
          </Footer.List.Item>
          <Footer.List.Item>
            <Link href="/kungliga-modebloggen" content="Kungligt mode" aria-label="Länk till kategorin Kungligt mode" />
          </Footer.List.Item>
          <Footer.List.Item>
            <Link href="/nobel" content="Nobel" aria-label="Länk till kategorin om Nobelfesten" />
          </Footer.List.Item>
        </Footer.List>
      </Footer.Column>

      <Footer.Column>
        <Footer.List.Title>Kontakta oss</Footer.List.Title>
        <Footer.List>
          <Footer.List.Item>
            <Link
              href="/kontaktaoss"
              content="Kontakta oss"
              aria-label="Länk till sidan för att kontakta Svensk Dam i olika ärenden"
            />
          </Footer.List.Item>
          <Footer.List.Item>
            <Link
              href="/svensk-damtidnings-lasarpanel"
              content="Läsarpanel"
              aria-label="Länk till sidan där du kan gå med i Svensk Dams läsarpanel"
            />
          </Footer.List.Item>
          <Footer.List.Item>
            <Link
              href="https://svenskdamtidning.ocast.com"
              target="_blank"
              content="Annonsera hos oss"
              aria-label="Länk till information om du vill annonsera på Svensk Dam"
            />
          </Footer.List.Item>
          <Footer.List.Item>
            <Link
              href="https://prenumerera.se/tidningar/svensk-damtidning"
              target="_blank"
              content="Prenumerera"
              aria-label="Länk till att prenumerera på Svensk Dam"
            />
          </Footer.List.Item>
        </Footer.List>
      </Footer.Column>

      {!isMobileApp && (
        <Footer.Column className="hide-in-mobile-app">
          <Footer.List.Title>Svensk Dam appen</Footer.List.Title>

          <Footer.List>
            <Footer.List.Item>
              <Link
                href="https://apps.apple.com/se/app/svensk-dam/id1530904500"
                target="_blank"
                content={<AppStoreBanner />}
                aria-label="Länk till att ladda ned Svensk Dams app i App store"
              />
            </Footer.List.Item>

            <Footer.List.Item>
              <Link
                href="https://play.google.com/store/apps/details?id=se.aller.svenskdam"
                target="_blank"
                content={<GooglePlayBanner />}
                aria-label="Länk till att ladda ned Svensk Dams app i Google play"
              />
            </Footer.List.Item>
          </Footer.List>
        </Footer.Column>
      )}
    </>
  );

  return <StandaloneFooter {...{ columns, copyright, description, editors, logo, soMe }} />;
};
