import { Component } from '@/types/component';
import { StandaloneEdge, StandaloneEdgeProps } from 'base/components/Edge';
import { cn } from '@/utils/cn';
import { Lato, Oswald } from 'next/font/google';

const fontPrimary = Oswald({
  display: 'swap',
  subsets: ['latin'],
  variable: '--font-primary',
  weight: ['400', '700'],
});

const fontTertiary = Lato({
  display: 'swap',
  style: ['normal', 'italic'],
  subsets: ['latin'],
  variable: '--font-tertiary',
  weight: '400',
});

export const SvenskdamStandaloneEdge: Component<StandaloneEdgeProps> = ({ children }) => {
  return <StandaloneEdge className={cn(fontPrimary.variable, fontTertiary.variable)}>{children}</StandaloneEdge>;
};
