import { Button } from '@/components/Button';
import { Icon } from '@/components/Icon';
import SvenskdamLogo from '@/svenskdam/public/image/svenskdam-logo.svg';
import { showOneTrustConsent } from '@/utils/oneTrust';
import { StandaloneNavigation, StandaloneNavigationProps } from 'base/components/Navigation';
import { Navigation } from 'base/components/Navigation/Navigation';
import { getNavigationDrawerMenu } from 'base/components/Navigation/utils/getNavigationDrawerMenu';
import GoogleNewsIcon from 'base/public/image/google-news.svg';
import { useMobileAppState } from 'lib/hooks/useMobileAppState';

const logo: StandaloneNavigationProps['logo'] = <SvenskdamLogo />;

const menu: StandaloneNavigationProps['menu'] = (
  <>
    <Navigation.Link href="/" content="Start" aria-label="Länk till startsidan" />
    <Navigation.Link href="/kungligt" content="Kungligt" aria-label="Länk till kategorin Kungligt" />
    <Navigation.Link href="/noje" content="Kändis" aria-label="Länk till kategorin Kändis" />
    <Navigation.Link href="/nobel" content="Nobel" aria-label="Länk till kategorin om Nobelfesten" />
    <Navigation.Link href="/video" content="TV" aria-label="Länk till kategorin TV som är Svensk Dams Video-sida" />
  </>
);

const controls: StandaloneNavigationProps['controls'] = (
  <button type="button" onClick={showOneTrustConsent} aria-label="Knapp för att öppna inställningarna för cookies">
    <Icon name="cookie" options={{ className: 'text-white', size: 'medium' }} />
  </button>
);

const getDrawer = () =>
  getNavigationDrawerMenu({
    menu: [
      {
        links: [
          { href: '/kungligt', content: 'Kungligt', options: { 'aria-label': 'Länk till kategorin Kungligt' } },
          { href: '/noje', content: 'Kändis', options: { 'aria-label': 'Länk till kategorin Nöje' } },
          {
            href: '/kungliga-modebloggen',
            content: 'Kungligt mode',
            options: { 'aria-label': 'Länk till kategorin Kungligt mode' },
          },
          { href: '/nobel', content: 'Nobel', options: { 'aria-label': 'Länk till kategorin om Nobelfesten' } },
          {
            href: '/video',
            content: 'TV',
            options: { 'aria-label': 'Länk till kategorin TV som är Svensk Dams Video-sida' },
          },
          { href: '/kronika', content: 'Krönikor', options: { 'aria-label': 'Länk till kategorin Krönikor' } },
          {
            href: '/korsord',
            content: (
              <>
                <span className="text-primary-700">NYTT!</span> Korsord
              </>
            ),
            options: { 'aria-label': 'Länk till spel' },
            tracking: {
              name: 'NYTT! Spel',
              extraInfo: [{ type: 'main_navigation_menu', campaign: 'keesing_games' }],
            },
          },
        ],
      },
      {
        links: [
          {
            href: 'https://prenumerera.se/tidningar/svensk-damtidning/?utm_source=svenskdam.se&utm_medium=menu&utm_campaign=svenskdamtidning',
            content: 'Prenumerera',
            options: { 'aria-label': 'Länk till att prenumerera på Svensk Dam' },
          },
          {
            href: '/nyhetsbrev',
            content: 'Nyhetsbrev',
            options: { 'aria-label': 'Länk till sidan för prenumerera på Svensk Dams nyhetsbrev' },
          },
          {
            href: '/alla-amnen',
            content: 'Alla ämnen',
            options: {
              'aria-label': 'Länk till sidan Alla ämnen där alla kategorier på Svensk Dam visas i alfabetisk ordning',
            },
          },
          {
            href: '/svensk-damtidnings-lasarpanel',
            content: 'Läsarpanel',
            options: { 'aria-label': 'Länk till sidan där du kan gå med i Svensk Dams läsarpanel' },
          },
          {
            href: '/vara-skribenter',
            content: 'Våra skribenter',
            options: { 'aria-label': 'Länk till sidan där alla skribenter för Svensk Dam listas' },
          },
          {
            href: '/creative-studio',
            content: 'Creative studio',
            options: { 'aria-label': 'Länk till sidan där alla Svensk Dams annonssamarbeten listas' },
          },
          { href: '#', content: 'Våra hovexperter', options: { className: 'pointer-events-none' } },
          {
            href: '/vara-skribenter/herman_lindqvist',
            content: (
              <>
                <span className="text-gray-600 text-headline-2xs">Historiker</span> Herman Lindqvist
              </>
            ),
            options: { variant: 'drawerSecondary' },
            tracking: {
              name: 'Historiker Herman Lindqvist',
            },
          },
          {
            href: '/vara-skribenter/johan_t_lindwall',
            content: (
              <>
                <span className="text-gray-600 text-headline-2xs">Chefredaktör</span> Johan T. Lindwall
              </>
            ),
            options: { variant: 'drawerSecondary' },
            tracking: {
              name: 'Chefredaktör Johan T. Lindwall',
            },
          },
          {
            href: '/vara-skribenter/helena_wiklund',
            content: (
              <>
                <span className="text-gray-600 text-headline-2xs">Hovreporter</span> Helena Wiklund
              </>
            ),
            options: { variant: 'drawerSecondary' },
            tracking: {
              name: 'Hovreporter Helena Wiklund',
            },
          },
        ],
      },
      {
        links: [
          {
            href: '/kontaktaoss',
            content: 'Kontakta oss',
            options: { 'aria-label': 'Länk till sidan för att kontakta Svensk Dam i olika ärenden' },
          },
          {
            href: '/om-oss',
            content: 'Om oss',
            options: { 'aria-label': 'Länk till sidan om Svensk Dam och dess historia', variant: 'drawerSecondary' },
          },
          {
            href: 'https://svenskdamtidning.ocast.com/',
            content: 'Annonsera',
            options: { 'aria-label': 'Länk till sidan om du vill annonsera på Svensk Dam', variant: 'drawerSecondary' },
          },
          {
            href: '/om-cookies',
            content: 'Om Cookies',
            options: {
              'aria-label': 'Länk till information och val gällande cookies på Svensk Dam',
              variant: 'drawerSecondary',
            },
          },
          {
            href: '#',
            content: 'Hantera preferenser',
            options: {
              'aria-label': 'Länk till sidan för att hantera dina cookie-preferenser',
              variant: 'drawerSecondary',
              onClick: (e) => {
                showOneTrustConsent();
                e.preventDefault();
              },
            },
          },
        ],
      },
    ],
    toggle: (
      <Navigation.Drawer.Toggle
        options={{
          $segment: {
            className: '!bg-white',
          },
        }}
      />
    ),
  });

const soMe: StandaloneNavigationProps['soMe'] = (
  <Navigation.SoMe.Group>
    <Navigation.SoMe.Headline>Följ Svensk Dam</Navigation.SoMe.Headline>

    <Navigation.SoMe
      href="https://www.facebook.com/svenskdamtidning/?locale=sv_SE"
      name="facebook"
      aria-label="Länk till Svensk Dams Facebook-sida"
    />
    <Navigation.SoMe
      href="https://www.instagram.com/svenskdamtidning/"
      name="instagram"
      aria-label="Länk till Svensk Dams Instagram-sida"
    />
    <Navigation.SoMe href="https://x.com/svenskdam" name="x" aria-label="Länk till Svensk Dams X-sida" />
    <Button
      link={{
        href: 'https://news.google.com/publications/CAAiEMOO7LLrA0PM4gJfbc3NYiYqFAgKIhDDjuyy6wNDzOICX23NzWIm?hl=sv&gl=SE&ceid=SE%3Asv',
        target: '_blank',
      }}
      options={{
        size: 'small',
        'aria-label': 'Länk till Google nyheter',
      }}
      content={
        <>
          <GoogleNewsIcon className="w-6" />
          <span className="whitespace-nowrap">Google nyheter</span>
        </>
      }
    />
  </Navigation.SoMe.Group>
);

export const SvenskdamStandaloneNavigation: typeof StandaloneNavigation = () => {
  const { isMobileApp } = useMobileAppState();

  if (isMobileApp) {
    return null;
  }

  const drawer = getDrawer();

  return (
    <StandaloneNavigation
      options={{
        className: 'hide-in-mobile-app',
        $search: {
          $submit: {
            options: {
              size: 'medium',
              colors: 'secondary',
            },
          },
        },
      }}
      {...{ logo, menu, controls, drawer, soMe }}
    />
  );
};
