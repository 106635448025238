import { tw } from '@/utils/tw';
import BaseTextFieldTheme from 'base/components/TextField/theme';

const Icon = tw.theme({
  extend: BaseTextFieldTheme.Icon,
});

const TextField = tw.theme({
  extend: BaseTextFieldTheme,
  slots: {
    label: `group-focus-within:text-royal-blue-700 group-data-[status=error]:text-primary-800 group-data-[status=error]:group-hover:text-primary-800 text-input-md text-gray-600 group-[&:hover:not(:focus-within)]:text-black`,
    input: `outline-royal-blue-200 focus:ring-royal-blue-700 group-data-[status=error]:outline-primary-200 group-data-[status=error]:hover:outline-primary-200 group-data-[status=error]:ring-primary-800 group-data-[status=error]:hover:ring-primary-800 bg-white outline outline-0 outline-offset-1 ring-1 ring-inset ring-black placeholder:text-gray-600 group-data-[status=error]:bg-white group-data-[status=error]:outline group-data-[status=error]:outline-4 group-data-[status=error]:ring-2 group-data-[status=error]:ring-inset group-data-[status=error]:placeholder:text-gray-600 group-data-[status=error]:hover:bg-white group-data-[status=error]:hover:outline group-data-[status=error]:hover:outline-4 group-data-[status=error]:hover:ring-2 hover:not-focus:bg-white hover:not-focus:outline-0 hover:not-focus:ring-1 hover:not-focus:ring-black focus:bg-white focus:outline-4 focus:ring-2`,
    caption: `group-data-[status=error]:text-primary-800 text-input-sm text-gray-600 group-focus-within:text-black group-hover:text-black`,
  },
  variants: {
    variant: {
      secondary: {
        label: `group-focus-within:text-input-xs group-data-[filled]:text-input-xs`,
      },
    },
  },
});

export default Object.assign(TextField, { Icon });
